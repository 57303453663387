:root {
  --primary-color: #e34243;
  --secondary-color: #fde5d9;
  --error-border-color: #dc3545;
}

input[type="radio"] {
  /* Change the color of the checked radio button */
  border-color: black;
}

input[type="radio"]:checked {
  /* Change the color of the checked radio button */
  background-color: red;
  border-color: red;
}

input[type="radio"]:focus {
  /* Change the color of the checked radio button */
  border-color: #fe8686;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(254, 134, 134, 0.25);
}

input[type="checkbox"] {
  /* Change the color of the checked radio button */
  border-color: black;
}

input[type="checkbox"]:checked {
  /* Change the color of the checked checkbox button */
  background-color: red;
  border-color: red;
}

input[type="checkbox"]:focus {
  /* Change the color of the checked checkbox button */
  border-color: #fe8686;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(254, 134, 134, 0.25);
}

p {
  margin: 0;
}

#divtransisi div {
  padding: 5px;
}

.visible {
  opacity: 1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hidden {
  opacity: 0;
  pointer-events: none; /* Disable interactions for hidden element */
  overflow: hidden; /* Hide any overflow content */
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.buttonBesarMerahBvic {
  padding: 1rem 3rem;
  border-color: #e34243;
  background-color: #e34243;
  color: white;
  border-radius: 3px;
}
.buttonSedangMerahBvic {
  padding: 0.75rem;
  border-color: #e34243;
  background-color: #e34243;
  color: white;
  border-radius: 3px;
}
.buttonKecilMerahBvic {
  padding: 0.5rem;
  border-color: #e34243;
  background-color: #e34243;
  color: white;
  border-radius: 3px;
}

.buttonBesarPutihBvic {
  padding: 1rem 3rem;
  border-color: #e34243;
  background-color: white;
  border-radius: 3px;
}
.buttonSedangPutihBvic {
  padding: 0.75rem;
  border-color: #e34243;
  background-color: white;
  border-radius: 3px;
}
.buttonKecilPutihBvic {
  padding: 0.5rem;
  border-color: #e34243;
  background-color: white;
  border-radius: 3px;
}

#datepicker {
  position: relative;
  color: white;
}

#datepicker:before {
  /* position: absolute; */
  /* top: 3px; */
  /* left: 3px; */
  content: attr(data-date);
  display: inline-block;
  color: black;
}

#datepicker::-webkit-datetime-edit,
#datepicker::-webkit-inner-spin-button,
#datepicker::-webkit-clear-button {
  display: none;
}

#datepicker::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0.5rem;
  right: 0;
  color: black;
  opacity: 1;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

body {
  font-family: "Poppins", sans-serif;
}

.bg-lightgray {
  background-color: lightgray !important;
}

.rtgs {
  transform: scale(0.6, 0.6);
  -ms-transform: scale(0.6, 0.6);
  -webkit-transform: scale(0.6, 0.6);
  -o-transform: scale(0.6, 0.6);
  -moz-transform: scale(0.6, 0.6);
}

#meetingSDKElement {
  position: relative !important;
}

/* CSS talk bubble */
.talk-bubble-blue {
  margin: 10px;
  display: inline-block;
  position: relative;
  width: 12rem;
  height: auto;
  background-color: #50a0ff;
}

.talk-bubble-green {
  margin: 10px;
  display: inline-block;
  position: relative;
  width: 12rem;
  height: auto;
  background-color: #22d882;
}

.border {
  border: 8px solid #666;
}

.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top-blue:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.left-top-blue:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #50a0ff transparent transparent transparent;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top-green:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.left-top-green:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  right: -20px;
  left: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #22d882 transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  font-family: "Poppins";
  padding: 1em;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5em;
  font-size: smaller;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.button-outline-red {
  background-color: white;
  color: red;
  border: 1px solid red;
}

.button-outline-red:hover {
  background-color: #dc3545;
  color: white;
  border: 1px solid #dc3545;
}

:hover.dropdownSumberDana {
  background-color: #e2e8f0;
}

.formBuilder2::placeholder {
  color: rgb(175, 175, 175)
}

small {
  font-size: 0.75rem;
}

/* .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff9999'/%3e%3c/svg%3e") !important;
} */

.animate-spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hoverRed {
  background-color: green;
}

.hoverRed:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.hoverParent:hover .moreBright {
  filter: brightness(1.2);
}

.hoverImage:hover {
  scale: 100%;
  animation: ease-in;
  animation-duration: 500ms;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* img,
video {
  display: block;
}

button[title="Participants"] {
  display: none 
} */
